import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { HiOutlineUsers } from "react-icons/hi2";
import "./styles.css";

const handleLoginClick = () => {
  window.location.href = "https://gms.38nguk.in/userlogin";
};

const Index = () => {
  return (
    <>
      <div className="d-flex">
        {/* <div className="mx-3">
          <button className="register_btn"><spna className="mx-1"><HiOutlineUsers /></spna>Registration Countdown</button>
        </div> */}
       
        <div className="login-btn" onClick={handleLoginClick}>
          <div>
            <i className="bi bi-person"></i>
          </div>
          <div>
            <span>Login</span>
          </div>
        </div>
      </div>

    </>

  );
};

export default Index;
