import "./footer.css";
import { useState } from "react";
import ChatBotMoga from "components/chatBotMoga";
import mualiimg from "../../assets/home2/Mascot/mascot.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Footerbottom = () => {
  const [openChatBox, setOpenChatBox] = useState(false);
  const [openChatBot, setOpenChatBot] = useState(true);

  const toggleSidebar = () => {
    // Toggle the sidebar visibility
    setOpenChatBot(!openChatBot);
    // If closing the sidebar, also close the chat box
    if (!openChatBot) {
      setOpenChatBox(false);
    }
  };

  return (
    <>
      <div>
        <button
          className="toggle-button-footer chat-bot-mauli"
          onClick={toggleSidebar}
        >
          Chatbot {openChatBot ? <FaArrowLeft /> : <FaArrowRight />}
        </button>
      </div>
      {!openChatBot && (
        <div className="chat-bot-mauli ">
          <div className="footer-body">
            {/* Your other content */}
            <div
              className="chat-box"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenChatBox(!openChatBox)}
            >
              <img
                loading="lazy"
                src={mualiimg}
                alt="Chatbot"
                className="chatbot-img"
              />
            </div>
          </div>
          {openChatBox && <ChatBotMoga setOpenChatBox={setOpenChatBox} />}
        </div>
      )}
    </>
  );
};

export default Footerbottom;
