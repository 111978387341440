import { FaArrowRightLong } from "react-icons/fa6";
import "./style.scss";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { games_data } from "../data";
import ktspl from "../../../assets/home2/header/Khelotech-white-logo.Final one png.png";

const Games = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("Records");
  
  return (
    <div className="score-main">
      <div className="sports">
        {games_data.map((item, index) => {
          return (
            <p
              key={index} 
              className={`menuHeading ${selectedSportType === item.heading ? "sports-active-btn" : ""}`}
              onClick={() => setSelectedSportType(item.heading)}
            >
              {item.heading}
            </p>
          );
        })}


      </div>
      {games_data?.map((item) => {

        if (item.heading === selectedSportType) {
          return (
            <>
              <div className="sport-description">
                <img src={item?.img} alt="" className={item?.heading} />
                <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                <p className="des">{item?.description}</p>
                <div className="learn-more">
                  {item?.link && (
                    <>
                      <a
                        href={item?.link}
                        // target="_blank"
                        rel="noopener noreferrer" // Security best practice
                        onClick={() => setIsMenuOpen(false)}
                        style={{
                          textDecoration: "none",
                          color: "white", // Default text color
                          transition: "color 0.3s", // Smooth transition for color change
                        }}
                      >
                        <p
                          style={{
                            "&:hover": {
                              color: "green", // Text color changes to green on hover
                            },
                          }}
                        >
                          Learn More
                        </p>
                      </a>
                      <FaArrowRightLong />
                    </>
                  )}
                </div>
              </div>
              {/* subsection to thew right */}

              <div className="sport-list-games">
                {item?.sideOptions?.map((el, index) => (
                  <Link
                    to={el?.link?.isRelativePath ? el?.link?.path : "/"}
                    // to={el?.link?.isRelativePath ? el?.link?.path : el?.link?.path ? el?.link?.path : '/'}
                    // {...(!el?.link?.isRelativePath
                    //   ? { target: "_blank", rel: el?.link?.path}
                    //   : {})}
                    onClick={() => {
                      if (el.link.isRelativePath) {
                        setIsMenuOpen(false);
                      }
                      if (!el?.link?.isRelativePath) {
                        window.open(el?.link?.path, el.link);
                      }
                    }}
                    style={{
                      textDecoration: "none",
                      color: "white", // Default text color
                    }}
                  >
                    <div className="each-game" key={index}>
                      {el.image && (
                        <div>
                          <div className="image-container-dropdowns">
                            <img src={el?.image} alt={el?.name} />
                          </div>
                        </div>
                      )}
                      <div className="des">
                        <p className="name">{el?.name}</p>
                        <p className="description">{el?.description}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          );
        }
        return null;
      })}
      <div className="ktspl-Logo-subnav">
        <img src={ktspl} alt={ktspl} />{" "}
        {/* <p >KHELO TECH</p> */}
      </div>
    </div>
  );
};

export default Games;
