import React, { useState, useEffect } from 'react';
import "./styles.scss";
import calculateTimeRemaining from 'components/home2/utterakhandClock/counterExp';

function LiveTimer({ initialDays, initialHours, initialMinutes, initialSeconds }) {

  const [time, setTime] = useState(calculateTimeRemaining());
  const [trimmedDays,setTrimmedDays] = useState('00');
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(calculateTimeRemaining);
      let days=   trimKeepLast2(time.days);
      setTrimmedDays(days)
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
    
  }, []);

  function trimKeepLast2(str) {
    let trimmedStr = str.replace(/^0+/, '');
      if (trimmedStr.length < 2) {
        // Pad with zeros if the length is less than 2
        trimmedStr = trimmedStr.padStart(2, '0');
    }   
    return trimmedStr;
}
  return (
    <div className='live-timing'>
      <div className='time-container'>
        <div className='num-live-time'>{trimmedDays}<span className="colon">:</span></div>
        <div className='days'>DAYS</div>
      </div>
      <div className='time-container'>
        <div className='num-live-time'>{time?.hour}<span className="colon">:</span></div>
        <div className='hours'>HOURS</div>
      </div>
      <div className='time-container'>
        <div className='num-live-time'>{time?.mins}<span className="colon">:</span></div>
        <div className='mins'>MINS</div>
      </div>
      <div className='time-container'>
        <div className='num-live-time'>{time?.sec}</div>
        <div className='sec'>SEC</div>
      </div>
    </div>
  );
}


export default LiveTimer;
