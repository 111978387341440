import { createSlice } from "@reduxjs/toolkit";
import { fetchMedia, fetchNews, fetchVideos } from "api/mediaApiService";
import { makeTextSmallAndAddHyphens } from "utils/helper";

const initialState = {
  news: [
    {
      img: "/news/logo_launch.jpg",
      description: `CM Pramod Sawant Unveils Dazzling Logo for 37th National Games

      Panaji, May 15, 2023 - The stage was set for an epic showcase of athleticism and national pride as Chief Minister Pramod Sawant took center stage to reveal the emblem of the eagerly awaited 37th National Games. In a remarkable moment of sportsmanship, Sawant, on a vibrant Sunday, unveiled the logo with a flourish - a symbolic bow and arrow, at the iconic Dr. Shyama Prasad Mukherjee stadium on the picturesque Taleigao Plateau. The message was clear: Goa stands poised to host an exhilarating sporting spectacle.
      
      Sawant confidently declared, "We are fully prepared to host the National Games 2023." His unshakable faith in Goa's infrastructure and human resources was palpable as he stated, "Every facet, from top-notch infrastructure to a well-trained team, has been meticulously arranged. Our commitment is to orchestrate an unforgettable event that will etch itself in the hearts and minds of all attendees. We are resolute in our mission to be exceptional hosts and ensure the Games unfold with flawless precision."
      
      Proudly reminiscing about Goa's successful hosting of prestigious international and national sporting events, including the renowned 73rd Ironman competition and a prestigious international table tennis tournament, the Chief Minister emphasized that Goa would harness this opportunity to further develop sports tourism.
      
      Goa's Sports Minister, Govind Gaude, then took center stage to illuminate the profound symbolism behind the logo. Brimming with enthusiasm, he declared, "Each element of this emblem encapsulates the essence of Goa's core values, culture, and identity. Our goal was to capture the spirit of Goa within this emblem, offering a captivating glimpse into the very soul of our state."
      
      "In sports, when an athlete leaves behind a lasting legacy, it creates an eternal source of inspiration for generations to come. Through this emblem, we pay homage to the perpetual cycle of sports and its transformative influence," he remarked.
      
      The event witnessed the presence of prominent figures, including SAG Executive Director and Joint CEO of the National Games Organizing Committee, Dr. Geeta S. Nagvekar, Joint Secretary and Acting CEO of the Indian Olympic Association, Kalyan Chaubey, and Tourism Minister Rohan Kaunte.
      
      Minister of State for Sports and Youth Affairs, Nisith Pramanik, delivered a stirring address, highlighting India's remarkable progress in the sports sector. He lauded Prime Minister Narendra Modi's visionary commitment to establish 1000 Khelo India Centers across the nation, with an impressive 950 centers already completed. Nisith assured the audience that the remaining centers would be operational by 2025, benefiting aspiring athletes in every corner of the country.
      
      Underlining the profound impact of these centers, the Minister explained their pivotal role in nurturing talent from rural areas and remote villages. By bringing sports facilities and training opportunities closer to their homes, the Khelo India Centers will empower and uplift athletes, providing them with a platform for success.
      
      The upcoming National Games in 2023, hosted by Goa, promise to be a testament to the state's dedication to excellence in sports event hosting. Under the visionary leadership of Chief Minister Pramod Sawant, an extraordinary sporting extravaganza awaits, destined to etch itself as a legacy in the annals of Indian sports.
      `,
    },
    {
      img: "/news/jeresy_launch.jpg",
      description: `Unveiling India's Grandeur: IOA Presents Ceremonial Dress and Player Kit for Hangzhou Asian Games
      Sep 06, 2023
      
      In a momentous event that resonated with national pride, the Indian Olympic Association (IOA) unveiled the official ceremonial dress and player kit for the Indian contingent set to shine at the much-anticipated 2022 Asian Games in Hangzhou, China. Scheduled from September 23 to October 8, this global sporting extravaganza promises to be a spectacle of unity and athletic excellence.
      
      A Dazzling Send-off Ceremony
      The grand revelation was marked by a glittering send-off ceremony graced by none other than the Union Minister for Information and Broadcasting and Youth Affairs and Sports, Anurag Singh Thakur. Joining him were IOA President and legendary sprinter PT Usha, along with other esteemed officials. Athletes from various disciplines, including Indian hockey goalkeepers PR Sreejesh and Savita Punia, shooting sensation Manu Bhaker, and the 2018 Asian Games shot put gold medalist Tajinderpal Singh Toor, represented the Indian contingent with unwavering pride.
      
      Fashioning Excellence: The Ceremonial Dress
      Crafted with finesse by the National Institute of Fashion Technology, the ceremonial dress is a masterpiece of design and cultural fusion. For women, it features a khaki textured saree, while men will sport a khaki kurta. The male athletes' bandhgala jackets and the women's high-neck blouses seamlessly blend Indian motifs and prints with quintessential Indian silhouettes, paying homage to the rich cultural tapestry of India. What makes this attire even more special is its commitment to nature, utilizing recycled fabrics that champion sustainability.
      
      Symbol of Pride and Identity
      Speaking on this remarkable occasion, Anurag Singh Thakur emphasized that the uniform is not merely clothing but a symbol of pride and identity for India's athletes. It proudly embodies India's self-reliance and showcases the nation's diverse heritage and design leadership. Thakur expressed his confidence in the team's ability to represent the young and new India while securing historic performances and a remarkable medal count. He called upon the nation to rally behind its athletes and cheer them to victory.
      
      The Inspired Playing Kit
      Designed by the talented Kashmiri designer Aaquib Wani, renowned for creating the Indian cricket team jersey, the player kit is a testament to India's artistic diversity. Drawing inspiration from the country's myriad art forms, it serves as a visual ode to India's incredible diversity and unity. With this kit, every athlete carries a piece of their home state onto the field, reinforcing the idea of unity in diversity.
      
      PT Usha's Expectations
      IOA President PT Usha expressed her anticipation for the 2022 Asian Games, where India will send its largest contingent ever, consisting of 634 athletes. She believes this squad has the potential to secure India's best-ever medal haul. PT Usha reaffirmed the IOA's commitment to placing athletes at the center of its universe, ensuring their well-being and success.
      
      A Diverse Contingent
      Rowing, with 33 members, boasts the largest unit after athletics, poised to claim medals in Hangzhou. Additionally, a 15-member Esports team will make its official debut at the Asian Games, adding a modern twist to this grand sporting tradition.
      
      A Glorious Past, A Promising Future
      In the previous edition of the Asian Games held in 2018, the Indian contingent clinched an impressive 70 medals, including 16 gold. As the nation eagerly awaits the 2022 Asian Games, the IOA's grand unveiling of ceremonial attire and player kit symbolizes India's unwavering commitment to excellence and unity on the global sports stage. Let the games begin!
      `,
    },
  ],
  media: [
    {
      img: "/media/1.jpg",
    },
    {
      img: "/media/2.jpg",
    },
    {
      img: "/media/3.jpg",
    },
    {
      img: "/media/4.jpeg",
    },
    {
      img: "/media/5.jpg",
    },
    {
      img: "/media/6.jpg",
    },
  ],
  videos: null,
  specificMedia: {},
};

const mediaSlice = createSlice({
  name: "mediaReducer",
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    setSpecificMedia: (state, action) => {
      state.specificMedia = action.payload;
    },
  },
});

export const { setNews, setMedia, setVideos, setSpecificMedia } =
  mediaSlice.actions;

export const getNews = (param = {}) => {
  return async (dispatch) => {
    try {
      const res = await fetchNews();
      const filtered = res?.data?.records?.filter(
        (item) => item?.status?.toLowerCase() === "enabled"
      );
      const mapped = filtered?.map((item) => ({
        ...item,
        // item?.img ??  `https://media-api.38nguk.in/api/media/${item?.image}`
        imageSrc: "https://media-api.38nguk.in/news/" + item?.newsImage,

        // imageSrc: "https://api.37nationalgamesgoa.in/news/" + item?.newsImage,
      }));
      dispatch(setNews(mapped));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getMedia = (param = {}) => {
  return async (dispatch) => {
    try {
      const res = await fetchMedia(param);
      const groupedData = res?.data?.records?.reduce((acc, item) => {
        const { sport_name, status, ...rest } = item;
        if (
          !acc[makeTextSmallAndAddHyphens(sport_name)] &&
          status?.toLowerCase() === "enabled"
        ) {
          acc[makeTextSmallAndAddHyphens(sport_name)] = [];
        }
        if (status?.toLowerCase() === "enabled") {
          acc[makeTextSmallAndAddHyphens(sport_name)]?.push(rest);
        }
        return acc;
      }, {});
      const filtered = res?.data?.records?.filter(
        (item) => item?.status?.toLowerCase() === "enabled"
      );
      dispatch(setMedia(filtered));
      dispatch(setSpecificMedia(groupedData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getVideos = (param = {}) => {
  return async (dispatch) => {
    try {
      const res = await fetchVideos(param);
      dispatch(setVideos(res?.data?.records));
    } catch (error) {
      console.error(error);
    }
  };
};

export default mediaSlice.reducer;
