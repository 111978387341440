import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import ScheduleAndResults from ".";
import dayjs from "dayjs";
import {  
  useRef, 
  useState,
  useEffect
} from "react";
import styles from "./SchedulePage.module.css";
import SportSchedule from "./SportSchedule";

const dateArray = [
  "24-January-2025",
  "25-January-2025",
  "26-January-2025",
  "27-January-2025",
  "28-January-2025",
  "29-January-2025",
  "30-January-2025",
  "31-January-2025",
  "01-February-2025",
  "02-February-2025",
  "03-February-2025",
  "04-February-2025",
  "05-February-2025",
  "06-February-2025",
  "07-February-2025",
  "08-February-2025",
  "09-February-2025",
  "10-February-2025",
  "11-February-2025",
  "12-February-2025",
  "13-February-2025",
  "14-February-2025",
];

function ScheduleOverview() {
  const currentDate = dayjs().format("DD-MMMM-YYYY");
  const currentDateIndex = dateArray.indexOf(currentDate);
  const [startIndex, setStartIndex] = useState(
    currentDateIndex > -1 ? currentDateIndex : 0
  );
  const [visibleDates, setVisibleDates] = useState(0); // Initialize to 0
  const dateContainerRef = useRef(null);
  const dateCardWidth = 100;

  useEffect(() => {
    const calculateVisibleDates = () => {
      if (dateContainerRef.current && dateContainerRef.current.offsetWidth) {
        const containerWidth = dateContainerRef.current.offsetWidth;
        const newVisibleDates = Math.max(
          3, // Minimum visible dates
          Math.floor(containerWidth / dateCardWidth)
        );
        // Math.floor(containerWidth / dateCardWidth);
        setVisibleDates(newVisibleDates);
      }
    };

    // Use ResizeObserver to handle rendering and resizing issues
    const resizeObserver = new ResizeObserver(() => {
      // Debounce the resize calculation to avoid infinite loops
      setTimeout(() => {
        calculateVisibleDates();
      }, 50);
    });

    if (dateContainerRef.current) {
      resizeObserver.observe(dateContainerRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (dateContainerRef.current) {
        resizeObserver.unobserve(dateContainerRef.current);
      }
    };
  }, [dateContainerRef, dateCardWidth]);

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (startIndex < dateArray.length - visibleDates) {
      setStartIndex(startIndex + 1);
    }
  };

  const isNextDisabled = startIndex + visibleDates >= dateArray.length;

  document.onkeydown = (e) => {
    e = e || window.event;
    if (e.key === "ArrowLeft") {
      handlePrevClick();
    } else if (e.key === "ArrowRight") {
      handleNextClick();
    }
  };

  return (
    <ScheduleAndResults>
      <div className={`${styles.dateTableView}`}>
        <div
          className={`grid-cols-lg-12 place-items-center schedule-header ${styles.gridOverview} c py-2`}
        >
          <div
            style={{ color: "var(--heading-color)" }}
            className="fw-bolder col-span-2 text-lg Sport_title"
          >
            Sports / Discipline
          </div>
          <div className="col-span-10 items-center d-flex gap-2 w-100">
            <div className={styles?.arrowContainer}>
              <BsArrowLeftCircle
                className={styles?.arrowIcon}
                onClick={handlePrevClick}
              />
            </div>
            <div className={styles?.dateContainer} ref={dateContainerRef} >
              {dateArray
                .slice(startIndex, startIndex + visibleDates)
                .map((item, id) => (
                  <div key={id + "-calendar"} >
                    <div className={styles?.dateCard}  >
                      <p className="m-0 ">{dayjs(item)?.format("MMM")}</p>
                      <p
                        className="text-3xl"
                        style={{ marginBottom: "3px" }}
                      >
                        {dayjs(item)?.format("DD")}
                      </p>
                    </div>
                    <p  className={styles.dayCalender}>
                      {dayjs(item)?.format("ddd")}
                    </p>
                  </div>
                ))}
            </div>
            <div
              className={styles?.arrowContainer}
              style={{ paddingRight: "7px" }}
            >
              <BsArrowRightCircle
                className={`${styles?.arrowIcon} ${
                  isNextDisabled ? styles?.disabled : ""
                }`}
                onClick={handleNextClick}
                disabled={isNextDisabled}
              />
            </div>
          </div>
        </div>
        <SportSchedule
          dateArray={dateArray}
          startIndex={startIndex}
          visibleDates={visibleDates}
        />
      </div>
    </ScheduleAndResults>
  );
}

export default ScheduleOverview;

