import React, { useState, useEffect } from "react";

import NavLogo from "./NavLogo";
import { 
  // useHistory,
  useLocation 
} from "react-router-dom";
import burgerMenu from "../../../assets/home2/header/Menu.png";
// import search from "../../../assets/home2/header/search.png";
import profile from "../../../assets/home2/header/profile-icon.svg";
import LoginUser from "./LoginBtn/index";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles.css";
import Scores from "components/SubNavbar/Scores";
import Games from "components/SubNavbar/Games";
import Schedule from "components/SubNavbar/Schedule";
import Teams from "components/SubNavbar/Teams";
import MileStones from "components/SubNavbar/Milestones";
import Latest from "components/SubNavbar/Latest";
import Marque from "./Marque/Index";
import logo from "../../../assets/home2/Body/logo-color.png";
import { HiOutlineUser } from "react-icons/hi";
import { FaBars } from "react-icons/fa";

// images
// import uk1 from "../../../assets/home2/Body/uk1.png";
// import uk2 from "../../../assets/home2/Body/uk2.png";
// import uk3 from "../../../assets/home2/Body/uk3.png";
// import uk4 from "../../../assets/home2/Body/uk4.png";
// import uk5 from "../../../assets/home2/header/uk1.png";
const Index = () => {
  
  const location = useLocation();
 
  // const history = useHistory();
  // const [openNavbar, setOpenNavbar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  // const [width, setWidth] = useState(window.innerWidth < 992);
  // const wrapperRef = useRef(null);
  const [activeLink, setActiveLink] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
// console.log("window.scrollY==>",window.scrollY)
  const [isPopupOpen, setIsPopupOpen] = useState({
    allSportOpen: false,
    nationalGamesOpen: false,
    medalTallyOpen: false,
    allTeamsOpen: false,
    statesOpen: false,
    unionTerritoriesOpen: false,
    sportsBoardOpen: false,
  });

  const [allSportsList, setAllSportsList] = useState([
    { label: "Aquatics", path: "/sports/aquatics" },
    { label: "Archery", path: "/sports/archery" },
    { label: "Athletics", path: "/sports/athletics" },
    { label: "Badminton", path: "/sports/badminton" },
    { label: "Basketball", path: "/sports/basketball" },
    { label: "Boxing", path: "/sports/boxing" },
    { label: "Cycling", path: "/sports/cycling" },
    // { label: "Equestrian", path: "/sports/equestrian" },
    { label: "Fencing", path: "/sports/fencing" },
    { label: "Football", path: "/sports/football" },
    // { label: "Golf", path: "/sports/golf" },
    { label: "Gymnastics", path: "/sports/gymnastics" },
    { label: "Hockey", path: "/sports/hockey" },
    { label: "Judo", path: "/sports/judo" },
    { label: "Kayaking and Canoeing", path: "/sports/canoeing" },
    { label: "Kabaddi", path: "/sports/kabaddi" },
    { label: "Kho-Kho", path: "/sports/kho-kho" },
    { label: "Handball", path: "/sports/handball" },
    { label: "Lawn bowl", path: "/sports/lawnball" },
    { label: "Judo", path: "/sports/judo" },
    { label: "Modern Pentathlon", path: "/sports/pentathlon" },
    { label: "Netball", path: "/sports/netball" },
    { label: "Rowing", path: "/sports/rowing" },
    // { label: "Sailing", path: "/sports/sailing" },
    { label: "Rugby Sevens", path: "/sports/rugby" },
    { label: "Shooting", path: "/sports/shooting" },
    { label: "Squash", path: "/sports/squash" },
    { label: "Table Tennis", path: "/sports/table-tennis" },
    { label: "Triathlon", path: "/sports/triathlon" },
    { label: "Volleyball", path: "/sports/volleyball" },
    { label: "Weightlifting", path: "/sports/weightlifting" },
    { label: "Wushu", path: "/sports/wushu" },
    { label: "Wrestling", path: "/sports/wrestling" },
    { label: "Taekwondo", path: "/sports/taekwondo" },
  ]);
  

  const handleLoginClick = () => {
    window.location.href = "https://gms.38nguk.in/userlogin";
  };
  
  const [ng, setNg] = useState([
    { label: "36 National Games", path: "/visit/36-national-games" },
    { label: "37 National Games", path: "https://37nationalgamesgoa.in/" },
  ]);

  const [medalTally, setMedalTally] = useState([
    { label: "Overview", path: "/" },
  ]);

  const [allTeams, setAllTeams] = useState([
    {
      label: "Andaman and Nicobar Islands",
      path: "/teams/andaman-and-nicobar-islands",
    },
    { label: "Andhra Pradesh", path: "/teams/andhra-pradesh" },
    { label: "Arunachal Pradesh", path: "/teams/arunachal-pradesh" },
    { label: "Assam", path: "/teams/bssam" },
    { label: "Bihar", path: "/teams/bihar" },
    { label: "Chandigarh", path: "/teams/chandigarh" },
    { label: "Chhattisgarh", path: "/teams/chhattisgarh" },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      path: "/teams/dadra-and-nagar-haveli-and-daman-and-diu",
    },
    { label: "Delhi", path: "/teams/delhi" },
    { label: "Goa", path: "/teams/Goa" },
    { label: "Gujarat", path: "/teams/Gujarat" },
    { label: "Haryana", path: "/teams/Haryana" },
    { label: "Himachal Pradesh", path: "/teams/Himachal-Pradesh" },
    { label: "Jammu and Kashmir", path: "/teams/Jammu-and-Kashmir" },
    { label: "Jharkhand", path: "/teams/Jharkhand" },
    { label: "Karnataka", path: "/teams/Karnataka" },
    { label: "Kerala", path: "/teams/Kerala" },
    { label: "Ladakh", path: "/teams/Ladakh" },
    { label: "Lakshadweep", path: "/teams/Lakshadweep" },
    { label: "Madhya Pradesh", path: "/teams/Madhya-Pradesh" },
    { label: "Maharashtra", path: "/teams/Maharashtra" },
    { label: "Manipur", path: "/teams/Manipur" },
    { label: "Meghalaya", path: "/teams/Meghalaya" },
    { label: "Mizoram", path: "/teams/Mizoram" },
    { label: "Nagaland", path: "/teams/Nagaland" },
    { label: "Odisha", path: "/teams/Odisha" },
    { label: "Puducherry", path: "/teams/Puducherry" },
    { label: "Punjab", path: "/teams/Punjab" },
    { label: "Rajasthan", path: "/teams/Rajasthan" },
    { label: "Sikkim", path: "/teams/Sikkim" },
    { label: "Tamil Nadu", path: "/teams/Tamil-Nadu" },
    { label: "Telangana", path: "/teams/Telangana" },
    { label: "Tripura", path: "/teams/Tripura" },
    { label: "Uttar Pradesh", path: "/teams/Uttar-Pradesh" },
    { label: "Uttarakhand", path: "/teams/Uttarakhand" },
    { label: "West Bengal", path: "/teams/West-Bengal" },
  ]);

  const [state, setState] = useState([
    { label: "Andhra Pradesh", path: "/teams/andhra-pradesh" },
    { label: "Arunachal Pradesh", path: "/teams/arunachal-pradesh" },
    { label: "Assam", path: "/teams/assam" },
    { label: "Bihar", path: "/teams/bihar" },
    { label: "Chhattisgarh", path: "/teams/chhattisgarh" },
    { label: "Goa", path: "/teams/goa" },
    { label: "Gujarat", path: "/teams/gujarat" },
    { label: "Haryana", path: "/teams/haryana" },
    { label: "Himachal Pradesh", path: "/teams/himachal-pradesh" },
    { label: "Jharkhand", path: "/teams/jharkhand" },
    { label: "Karnataka", path: "/teams/karnataka" },
    { label: "Kerala", path: "/teams/kerala" },
    { label: "Madhya Pradesh", path: "/teams/madhya-pradesh" },
    { label: "Maharashtra", path: "/teams/maharashtra" },
    { label: "Manipur", path: "/teams/manipur" },
    { label: "Meghalaya", path: "/teams/meghalaya" },
    { label: "Mizoram", path: "/teams/mizoram" },
    { label: "Nagaland", path: "/teams/nagaland" },
    { label: "Odisha", path: "/teams/odisha" },
    { label: "Punjab", path: "/teams/punjab" },
    { label: "Rajasthan", path: "/teams/rajasthan" },
    { label: "Sikkim", path: "/teams/sikkim" },
    { label: "Tamil Nadu", path: "/teams/tamil-nadu" },
    { label: "Telangana", path: "/teams/telangana" },
    { label: "Tripura", path: "/teams/tripura" },
    { label: "Uttar Pradesh", path: "/teams/uttar-pradesh" },
    { label: "Uttarakhand", path: "/teams/uttarakhand" },
    { label: "West Bengal", path: "/teams/west-bengal" },
  ]);

  const [unionTerritories, setUnionTerritories] = useState([
    {
      label: "Andaman and Nicobar Islands",
      path: "/teams/andaman-and-nicobar-islands",
    },
    { label: "Chandigarh", path: "/teams/chandigarh" },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      path: "/teams/dadra-and-nagar-haveli-and-daman-and-diu",
    },
    { label: "Lakshadweep", path: "/teams/lakshadweep" },
    { label: "Delhi", path: "/teams/delhi" },
    { label: "Puducherry", path: "/teams/puducherry" },
    { label: "Jammu & Kashmir", path: "/teams/jammu-and-kashmir" },
    { label: "Ladakh", path: "/teams/ladakh" },
  ]);

  const [sportsBoard, setStateBoard] = useState([
    { label: "Services Sports Control Board", path: "/teams/sscb" },
  ]);

  const subMenuList = [
    {
      submenu: "games",
      list: [
        { label: "Records", path: "/games/records" },
        { label: "Medal Tally", path: "/games/medals" },
        { label: "All Sports", path: "/allsports" },
        { label: "Fixtures", path: "/schedule/overview" },
        // { label: "Logo", path: "/" },
      ],
    },
    {
      submenu: "About",
      list: [
        { label: "Mauli(Mascot)", path: "/info/about-mauli" },
        { label: "Milestones", path: "/milestone/all" },
        { label: "Uttarakhand", path: "/info/about-uttrakhand" },
        { label: "Green Games", path: "/green-game" },
        { label: "Previous National Games", path: "/visit/national-games" },
        { label: "IOA", path: "https://olympic.ind.in/" },
        { label: "Contact us", path: "/info/contact-us" },
        
        

      ],
    },
    {
      submenu: "News & Gallery",
      list: [
        { label: "News", path: "/latest/news" },
        { label: "Photos", path: "/latest/photos" },
        { label: "Videos", path: "/latest/videos" },
        // { label: "MILESTONES", path: "/milestone/all" },
      ],
    },
    // {
    //   submenu: "scores",
    //   list: [
    //     { label: "All Sport", path: "" },
    //     // { label: "Olympics Sports", path: "/" },
    //     // { label: "Indigenous Sports", path: "/" },
    //   ],
    // },
    // {
    //   submenu: "teams",
    //   list: [
    //     { label: "All Teams", path: "/teams" },
    //     { label: "States", path: "/teams" },
    //     { label: "Union Territories", path: "/teams" },
    //     { label: "Sports Boards", path: "/teams" },
    //   ],
    // },
    
    // {
    //   submenu: "milestones",
    //   list: [
    //     { label: "News", path: "/latest/news" },
    //     { label: "Photos", path: "/latest/photos" },
    //     { label: "Videos", path: "/latest/videos" },
    //   ],
    // },
    // {
    //   submenu: "stats",
    //   list: [
    //     { label: "News", path: "/latest/news" },
    //     { label: "Photos", path: "/latest/photos" },
    //     { label: "Videos", path: "/latest/videos" },
    //   ],
    // },
  ];

  const scrollToTop = () => {
    const element = document.getElementById("home-body");
    if (element) {
      element.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  const handleClick = (menu) => {
    if (selectedMenu === menu?.submenu) {
      setIsMenuOpen((b_val) => !b_val);
      return;
    }

    setSelectedMenu(menu?.submenu);
    setIsMenuOpen(true);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const openPopup = (popupKey) => {
    setIsPopupOpen((prevState) => ({
      ...prevState,
      [popupKey]: true,
    }));
  };

  // console.log(isPopupOpen, "==>")

  const closePopup = (popupKey) => {
    setIsPopupOpen((prevState) => ({
      ...prevState,
      [popupKey]: false,
    }));
  };

  
  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/") {
        // Check if the current path is "/"
        setIsScrolled(window.scrollY > 0);
      } else if (location.pathname.startsWith("/")) {
        // Check if the current path matches "/*"
        setIsScrolled(true);
      }
    };

    // Set `isScrolled` immediately based on the route
    if (location.pathname === "/") {
      setIsScrolled(window.scrollY > 0);
    } else if (location.pathname.startsWith("/")) {
      setIsScrolled(true);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  // useEffect(() => {
  //   if (isMobileMenuOpen) {
  //     document.body.style.overflow = "hidden"; // Prevent background scroll
  //   } else {
  //     document.body.style.overflow = "auto"; // Enable background scroll when menu is closed
  //   }

  //   return () => {
  //     document.body.style.overflow = "auto"; // Cleanup on unmount
  //   };
  // }, [isMobileMenuOpen]);


  
  return (
    <>
      <div>
      <Marque />
      <div className="mobile-nav-container">
        <div className="mobile-navbar" style={{ top: isScrolled ? "0" : "35px" }} >
          <div className="burger-container">
            {/* <img
              onClick={toggleMobileMenu}
              src={burgerMenu}
              alt="logo"
              className="logo hamburgerMenu"
              style={{
                width: "30px",
                marginBottom: "0px",
              }}
            /> */}
            <div className="hamburgerMenu_menu"  onClick={toggleMobileMenu}>
              <FaBars className="hamburgerMenu_menu_icon"/>
            </div>
            
          </div>
          <div className="mobile_header_logo" onClick={() => window.location.href = "/"}>
            <img src={logo} alt={logo} />
          </div>

          <div className="hamburgerMenu_menu" onClick={handleLoginClick}>
            {/* <img
              src={profile}
              alt="profile"
              style={{ width: "30px", marginBottom: "0px" }}
            /> */}
            <HiOutlineUser className="hamburgerMenu_menu_icon"/>
            
          </div>
        </div>
      </div>
      </div>
     

      <div className="navbar-main">
        <div className={`navbar-fields ${(isScrolled || toggle || activeLink || isMenuOpen) ? "scrolled" : ""}`}>
          <div className="left-navbar-items">
            {/* <div className="burger-container">
            <img
              onClick={toggleMobileMenu}
              src={burgerMenu}
              alt="logo"
              className="logo hamburgerMenu"
              style={{
                width: "40px",
                marginBottom: "0px",
              }}
            />
          </div> */}
            <div className="navLogo ">
              <NavLogo
                click={() => {
                  scrollToTop();
                  setActiveLink(null);
                  settoggle(false)
                  setIsMenuOpen(false)
                }}
                isScrolled={isScrolled}
              />
            </div>
          </div>
          <div className="nav-items-dropdows ">
            {subMenuList.map((menu, index) => (
              <div
                key={index}
                onClick={() => {
                  setActiveLink(index);
                  handleClick(menu);
                  settoggle(!toggle);
                }}
              >
                <h6
                  className={`${
                    index === activeLink ? "header_active_menu" : null
                  }`}
                >
                  {menu.submenu.toUpperCase()}
                  {menu.submenu.toUpperCase() !== "STATS" &&
                    (toggle && index === activeLink ? (
                      <i
                        className={`bi bi-chevron-up ${
                          index === activeLink
                            ? "header_active_menu dowen_arrow"
                            : ""
                        }`}
                        
                      ></i>
                      
                    ) : (
                      <i
                        className={`bi bi-chevron-down ${
                          index === activeLink ? "header_active_menu" : ""
                        }`}
                        
                      ></i>
                    ))}
                </h6>
              </div>
            ))}
          </div>
          <div className="right-navbar-items">
            {/* <div className="menu_search_box">
            <div>
              <i className="bi bi-search"></i>
              <input type="text" placeholder="Search..." />
            </div>
          </div> */}
            <div className="login-btn-none">
              <LoginUser />
            </div>
          </div>
          {/* 
        <div className="searchBtn">
          <img
            src={profile}
            alt="profile"
            style={{ width: "40px", marginBottom: "0px" }}
          />
        </div> */}
        </div>

       

        {isMenuOpen && !isMobile && (
          <div className="sub-navbar-main">
            {selectedMenu === "scores" && (
              <Scores setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "games" && (
              <Games setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "About" && (
              <Schedule setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "teams" && (
              <Teams setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "milestones" && (
              <MileStones setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "News & Gallery" && (
              <Latest setIsMenuOpen={setIsMenuOpen} />
            )}
          </div>
        )}

        {isMobile && isMobileMenuOpen && (
          <>
            <div className="mobile-menu" style={{ top: isScrolled ? "0px" : "55px" }}>
              {subMenuList.map((menu, index) => (
                <div
                  key={index}
                  className={`mobile-menu-item ${
                    isMenuOpen && selectedMenu === menu.submenu ? "open" : ""
                  }`}
                >
                  <h6
                    className={`${index === activeLink ? "list-item" : "p-3"} `}
                    onClick={() => {
                      setActiveLink(index);
                      handleClick(menu);
                      settoggle(!toggle);
                    }}
                  >
                    {menu.submenu.toUpperCase()}
                    {menu.submenu.toUpperCase() !== "STATS" &&
                      (toggle && activeLink === index ? (
                        <i className="bi bi-chevron-up px-2 active_arrow_icon"></i>
                      ) : (
                        <i className="bi bi-chevron-down px-2"></i>
                      ))}
                  </h6>
                  {isMenuOpen && selectedMenu === menu.submenu && (
                    <ul className="mobile-submenu ">
                      {menu.list.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="mobile-submenu-list-item"
                          onClick={(e) => {
                            if (item.label === "All Sport") {
                              e.stopPropagation();
                              openPopup("allSportOpen");
                            } else if (item.label === "National Games") {
                              e.stopPropagation();
                              openPopup("nationalGamesOpen");
                            } else if (item.label === "Medals Tally") {
                              e.stopPropagation();
                              openPopup("medalTallyOpen");
                            } else if (item.label === "All Teams") {
                              e.stopPropagation();
                              openPopup("allTeamsOpen");
                            } else if (item.label === "States") {
                              e.stopPropagation();
                              openPopup("statesOpen");
                            } else if (item.label === "Union Territories") {
                              e.stopPropagation();
                              openPopup("unionTerritoriesOpen");
                            } else if (item.label === "Sports Boards") {
                              e.stopPropagation();
                              openPopup("sportsBoardOpen");
                            } else {
                              window.location.href = item.path;
                            }
                          }}
                        >
                          <a href={item.path} className="submenu_title" >{item.label}</a>
                          {/* <i className="bi bi-arrow-right text-white"></i> */}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>

            {/* Popup Component All sport */}
            {/* {isPopupOpen?.allSportOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("allSportOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>All Sports</h4>
                  </div>
                  <ul className="sports-list">
                    {allSportsList.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )} */}

            {/* Popup Component national games*/}
            {isPopupOpen?.nationalGamesOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("nationalGamesOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>National Games</h4>
                  </div>
                  <ul className="sports-list">
                    {ng.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Popup Component Medal Tally*/}
            {isPopupOpen?.medalTallyOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("medalTallyOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>Medal Tally</h4>
                  </div>
                  <ul className="sports-list">
                    {medalTally.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Popup Component All Teams */}
            {isPopupOpen?.allTeamsOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("allTeamsOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>All Teams</h4>
                  </div>
                  <ul className="sports-list">
                    {allTeams.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Popup Component States*/}
            {isPopupOpen?.statesOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("statesOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>States</h4>
                  </div>
                  <ul className="sports-list">
                    {state.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Popup Component Union Territories*/}
            {isPopupOpen?.unionTerritoriesOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("unionTerritoriesOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>Union Territories</h4>
                  </div>
                  <ul className="sports-list">
                    {unionTerritories.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Popup Component Sports Board*/}
            {isPopupOpen?.sportsBoardOpen && (
              <div className="popup-overlay-header">
                <div className="popup-content-header">
                  <button
                    className="back-button-header"
                    onClick={(e) => {
                      e.stopPropagation();
                      closePopup("sportsBoardOpen");
                    }}
                  >
                    &lt; Back
                  </button>
                  <div className="popup-header">
                    <h4>Sports Board</h4>
                  </div>
                  <ul className="sports-list">
                    {sportsBoard.map((sport, index) => (
                      <li key={index}>
                        <a
                          href={sport.path}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {sport.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Index;
