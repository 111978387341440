import { FaArrowRightLong } from "react-icons/fa6";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { teams_data } from "../data";
import ktspl from "../../../assets/home2/header/Khelotech-white-logo.Final one png.png";
import "./styles.css";

const Teams = ({ setIsMenuOpen }) => {
  const [selectedTeamType, setSelectedTeamType] = useState("All Teams");


  return (
    <>
      <div className="score-main">
        <div className="sports">
          {teams_data?.map((item) => {
            return (
              <p
                className={
                  selectedTeamType === item?.value ? "sports-active-btn" : ""
                }
                onClick={() => setSelectedTeamType(item?.value)}
              >
                {item?.heading}
              </p>
            );
          })}
        </div>

        {teams_data?.map((item) => {
          if (item.value === selectedTeamType) {
            return (
              <>
                <div className="sport-description">
                  <img src={item?.img} alt="" className="img" />
                  <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                  <p className="des">{item?.description}</p>
                  <div className="learn-more">
                    <Link
                      to={item?.link}
                      onClick={(e) => {
                        setIsMenuOpen(false);
                      }}
                      style={{ textDecoration: "none",color: "#fff" }}
                    >
                      {" "}
                      <p>Learn More</p>
                    </Link>
                    <FaArrowRightLong />
                  </div>
                </div>
                {/* subsection to thew right */}
                <div className="teams-list">
                  {item?.sideOptions?.map((el, index) => (
                    <Link
                      to={el.link}
                      style={{ textDecoration: "none" }}
                      key={index}
                      onClick={() => setIsMenuOpen(false)} // Close the dropdown on route change
                    >
                      <div className="each-game">
                      <div>
                      <div className="image-container-dropdowns">
                        <img src={el?.image} alt={el?.name} />
                      </div>
                    </div >
                        <div className="des" >
                          <p className="name">{el?.heading}</p>
                          {/* <p className="description">{el?.description}</p> */}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            );
          }
          return null;
        })}
          <div className="ktspl-Logo-subnav">
        <img src={ktspl} alt={ktspl} />{" "}
        {/* <p >KHELO TECH</p> */}
      </div>
      </div>
    </>
  );
};
export default Teams;

// (

//   <div className="top_nav-teams ">
//     {teams_data.map((el, index) => {
//       return (
//         <>
//           <Link
//             to={el?.link}
//             onClick={(e) => {
//               setIsMenuOpen(false);
//             }}
//             style={{ textDecoration: "none" }}
//           >
//             <div className="each-game" key={index}>
//               <img src={el?.image} alt={el?.name} />
//               <div className="des">
//                 <p className="name">{el?.heading}</p>
//                 <p className="description">{el?.description}</p>
//               </div>
//             </div>
//           </Link>
//         </>
//       );
//     })}
//   </div>
// );

// return <>
// <Link
//        to={item?.link}
//        onClick={(e) => {
//          setIsMenuOpen(false);
//        }}
//        style={{ textDecoration: "none" }}
//      >
//   <div className="sport-description  sport-description-teams">
//    <img src={olympicLogo} alt="" className="olympic-img" />
//    <p style={{ fontWeight: "600" }}>{item?.heading}</p>
//    {/* <p className="des">{item?.description}</p> */}
//    <div className="learn-more">
//      {/* <Link
//        to={item?.link}
//        onClick={(e) => {
//          setIsMenuOpen(false);
//        }}
//        style={{ textDecoration: "none" }}
//      >
//        {" "}
//        <p>Learn More</p>
//      </Link> */}
//      {/* <FaArrowRightLong /> */}
//    </div>
//  </div>
//  </Link>
// </>
