import axios from "axios";
import { parseAxiosError } from "components/common/constant";

// const API_BASE = "https://api.37nationalgamesgoa.in/api";
const API_BASE = "https://media-api.38nguk.in/api/";



export const fetchMilestoneCategory = async () => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `news/category/all?isMilestone=Yes`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchMilestoneByCategory = async (categoryId) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/news/all?limit=1000&catId=${categoryId}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};
