import { useState, useEffect } from "react";
import PageTitle from "components/pageTitle/PageTitle";
// import TopClock from "../../components/home2/Body/TopClock/Index";
import styles from "./styles.module.css";
import TimerBannerCard from "components/home2/timerBannerCard/TimerBannerCard";
import SportEventbannerCard from "components/home/sports/SportEventbannerCard";
import SportCarosualCard from "components/home/sports/SportCarosualCard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { useLocation } from "react-router-dom";

const Banner = ({
  text,
  firstText,
  sportPage,
  children,
  img,
  clock = 1,
  pagetitle,
  subPageTitle,
  hideSection,
  pageSubTitle,
  childPageSubTitle,
  subPageTitleSecondary,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  // const location = useLocation();
  // const sportName = location.pathname.split("/")[2]?.toLowerCase();
  const subPageTitlesSecondary = subPageTitleSecondary?.replace(/-/g, " ");
  // console.log("subPageTitlesSecondary", subPageTitlesSecondary);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 770);
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSpecialPage =
    location.pathname === "/latest/videos" ||
    location.pathname === "/latest/news" ||
    location.pathname === "/milestone/all";
  //console.log("subPageTitleSecondary==>",subPageTitleSecondary)

  return (
    <>
      <div
        className={`${
          sportPage ? styles.sportPagebannerWrapper : styles.bannerContainer_wrapper
        }`}
      >
        {/* <PageTitle motherMenu={pagetitle} activeMenu={subPageTitle} secondaryActiveMenu={subPageTitlesSecondary} subMenu={pageSubTitle} childSubMenu={childPageSubTitle} /> */}
        <div className={`${styles.bannerContainer}`}>
          {/* <img
              src={
                isMobile
                  ? "/assets/images/bannerResponsive.png"
                  : img || "/assets/images/BANNER.png"
              }
              alt="banner"
              className={`${styles.bannerImage}`}
            /> */}
          {(subPageTitleSecondary || text) && (
            <h1
              id="participantTeam"
              className={`${styles.participantTeam}`}
              style={{
                lineHeight: isSpecialPage ? "60px" : "45px",
              }}
            >
              {(subPageTitleSecondary || text)?.replace(/_/g, " ")}
            </h1>
          )}

          {sportPage &&  <>
          <div className={`${styles.sportbannerSection}`}>
          <SportEventbannerCard />
          <SportCarosualCard /> 
          </div>
           
          </>}

          {sportPage ? (
            <>
              <div className={`${styles.sport_banner_wrapper}`}>
                {/* <SportEventbannerCard /> */}
                {/* <SportCarosualCard /> */}
              </div>
            </>
          ) : (
            <>
              {/* {!["gallery"].includes(hideSection) && <TimerBannerCard />} */}
            </>
          )}

          {children}

          {clock && (
            <div
            // style={{
            //   display: "flex",
            //   justifyContent: "space-around",
            //   marginTop: "-2rem",
            //   zIndex: "100",
            // }}
            >
              {/* <TopClock /> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
