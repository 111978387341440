import axios from "axios";
import { parseAxiosError } from "components/common/constant";
import { OLD_NATIONAL_GAMES_API, NEW_NATIONAL_GAMES_API } from "./commonapi";

const API_BASE = `https://media-api.38nguk.in/api`;
// const API_BASE = `${NEW_NATIONAL_GAMES_API}/api`;

export const fetchNews = async () => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/news/all?limit=1000`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchMedia = async () => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/media/all?limit=1000`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchVideos = async (page = 1) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/video/all?page=${page}&limit=10`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};
