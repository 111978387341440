import { FaArrowRightLong } from "react-icons/fa6";
// import olympicLogo from "../../../assets/home2/header/olympic-games.png";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { latest_data } from "../data";
import ktspl from "../../../assets/home2/header/Khelotech-white-logo.Final one png.png";

const Teams = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("News");
  return (
    <div className="score-main">
      <div className="sports">
        {latest_data?.map((item) => {
          return (
            <p
              className={
                selectedSportType === item?.value ? "sports-active-btn" : ""
              }
              onClick={() => setSelectedSportType(item?.value)}
            >
              {item?.heading}
            </p>
          );
        })}
      </div>
      {latest_data?.map((item) => {
        if (item.value === selectedSportType) {
          return (
            <>
              <div className="sport-description">
                <img src={item?.img} alt="" className="olympic-img" />
                <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                <p className="des">{item?.description}</p>
                <div className="learn-more">
                  <Link
                    to={item?.link}
                    onClick={(e) => {
                      setIsMenuOpen(false);
                    }}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {" "}
                    <p>Learn More</p>
                  </Link>
                  <FaArrowRightLong />
                </div>
              </div>
              {/* subsection to thew right */}
              <div className="sport-list-latest">
                {item?.sideOptions?.map((el, index) => (
                  <div className="each-game" key={index}>
                    <div>
                      <div className="image-container-dropdowns">
                        <img src={el?.image} alt={el?.name} />
                      </div>
                    </div>
                    <div className="des">
                      <p className="name">{el?.name}</p>
                      <p className="description">{el?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        }
        return null; // Ensure a value is returned even if the condition is not met
      })}

<div className="ktspl-Logo-subnav">
        <img src={ktspl} alt={ktspl} />{" "}
        {/* <p >KHELO TECH</p> */}
      </div>
    </div>
  );
};
export default Teams;
