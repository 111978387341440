export const footer1 = [
  // { name: "Games", path: "/sports/archery" },
  { name: "Records", path: "/games/records" },
  { name: "Medal Tally", path: "/games/medals" },
  // { name: "Venues", path: "/" },
  { name: "Sports", path: "/allsports" },
  { name: "Fixture", path: "/schedule/overview" },
]

export const allSportsOptions = [



];


export const sportsOptions = [
  { name: "Mauli", path: "/info/about-mauli" },
  { name: "Uttarakhand", path: "/info/about-uttrakhand" },
  { name: "Green Games", path: "/green-game" },
  { name: "National Games", path: "/visit/national-games" },
  { name: "Milestone", path: "/milestone/all" },



  

];

export const footer3 = [

 
  { name: "News", path: "/latest/news" },
  { name: "Photos", path: "/latest/photos" },
  { name: "Videos", path: "/latest/videos" },
  // { name: "Hightlights", path: "/" },
 

];


export const footer4 = [

  { name: "Squash", path: "/sports/squash" },
  { name: "Table Tennis", path: "/sports/table-tennis" },
  { name: "Taekwondo", path: "/sports/taekwondo" },
  { name: "Tennis", path: "/sports/tennis" },
  { name: "Triathlon", path: "/sports/triathlon" },
  { name: "Volleyball", path: "/sports/volleyball" },
  { name: "Weightlifting", path: "/sports/weightlifting" },
  { name: "Wrestling", path: "/sports/wrestling" },
  { name: "Wushu", path: "/sports/wushu" },
  { name: "Yogasana", path: "/sports/yogasana" },

];

export const footer5 = [
  
 
 

];