import "./chatBotMoga.css";
import { useState, useEffect, useRef } from "react";
import ChatBothome from "./chatBothome";
import Conversation from "./conversation";

const ChatBotMoga = ({ setOpenChatBox }) => {
  const wrapperRef = useRef(null);
  const [userName, setuserName] = useState("");
  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenChatBox(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line
  }, [wrapperRef]);

  return (
    <div className="chat-intercom-main " ref={wrapperRef}>
      {openChat && userName.length ? (
        <Conversation
          userName={userName}
          setOpenChat={setOpenChat}
          setOpenChatBox={setOpenChatBox}
        />
      ) : (
        <ChatBothome
          setuserName={setuserName}
          userName={userName}
          setOpenChat={setOpenChat}
          setOpenChatBox={setOpenChatBox}
        />
      )}
    </div>
  );
};

export default ChatBotMoga;
