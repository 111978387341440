import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Button, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { getAllSportsData } from "redux/reducers/sportsReducer";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SportCarosualCard = () => {
  const sports = window.location.pathname.split("/")?.[2];
  const dispatch = useDispatch();
  const history = useHistory();

  const { allSportsData: sportsData } = useSelector(
    (state) => state.sportsReducer
  );

  const [loading, setLoading] = useState(true); // Local loading state
  const carouselRef = useRef(null); // Ref for carousel instance

  useEffect(() => {
    setLoading(true);
     dispatch(
      getAllSportsData()
    ).finally(() => setLoading(false));
  }, [sports, dispatch]);

  const filteredValues = sportsData || [];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  // Handlers for the Previous and Next buttons
  const handleNext = () => {
    carouselRef.current?.next();
  };

  const handlePrevious = () => {
    carouselRef.current?.previous();
  };

  console.log(filteredValues, "-->")

  return (
    <Row className="sports_carousel_wrap center_title">
      <Col lg={11}>
        <div className="d-flex align-items-center sport_carosule_wrapper">
          {/* Previous Arrow */}
          {filteredValues?.length > 0 && <div
            className="previous_sport_Carosule arrow_sport_Carosule"
            onClick={handlePrevious} // Fixed usage here
            role="button"
          >
            <MdKeyboardArrowLeft size={40} />
          </div>}
          <div className="sport_carosule_container">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : filteredValues?.length > 0 ? (
              <Carousel
                ref={carouselRef} // Attach carousel reference here
                responsive={responsive}
                ssr={true}
                swipeable={true}
                draggable={true}
                infinite={true}
                showDots={false}
                arrows={false}
                partialVisible={false}
              >
                {filteredValues?.map((card, index) => (
                  <Col key={index} className="p-2">
                    <Card className="sport_carosule_card">
                      <Card.Body>
                        <div className="sport_carosule_heading" style={{marginBottom:".5rem"}}>
                          {card?.sport_name}
                        </div>
                        <Card.Subtitle className="mb-2 text-muted sport_carosule_subtitle">
                          {card?.event}
                        </Card.Subtitle>
                        <p>
                          <div className="sport_carosule_para">
                          {card?.venue_name?.trim() ? card.venue_name : "Not declared"}
                          </div>
                          </p>
                          <h2 className="sport_carosule_date" >
                            {dayjs(card?.start_date).format("DD MMM")} to{" "}
                            {dayjs(card?.end_date).format("DD MMM YYYY")}</h2>
                        
                        {/* <Button
                          className={
                            card.gender === "Male"
                              ? "btn btn-primary sport_carosule_btn_male"
                              : card.gender === "Female"
                              ? "btn btn-pink sport_carosule_btn_female"
                              : "btn btn-purple sport_carosule_btn_mixed"
                          }
                        >
                          {card.gender.toUpperCase()}
                        </Button> */}

                        <Button 
                        className="btn btn-secondary sport_carosule_btn_view" 
                        onClick={() => {
                          let transformedName = card?.rf_sport_db_name;
                          // Handle specific case for "canoeing_&_kayaking"
                          if (transformedName === "kayaking_and_canoeing") {
                            transformedName = "canoeing";
                          } else {
                            // Replace underscores with hyphens for other cases
                            transformedName = transformedName?.replace(/_/g, '-');
                          }

                          history.push(`/sports/${transformedName}`);
                        }}
                      >
                        VIEW RESULTS
                      </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Carousel>
            ) : (
              <p
                className="text-center mt-5"
                style={{ color: "#212529", fontSize: "25px" }}
              >
                No Sports available......
              </p>
            )}
          </div>
          {/* Next Arrow */}
          {filteredValues?.length > 0 && <div
            className="next_sport_Carosule arrow_sport_Carosule"
            onClick={handleNext} // Fixed usage here
            role="button"
          >
            <MdOutlineKeyboardArrowRight size={40} />
          </div>}
        </div>
      </Col>
    </Row>
  );
};

export default SportCarosualCard;
