import ktspl from "../../../assets/home2/header/Khelotech-white-logo.Final one png.png";
import olympicLogo from "../../../assets/home2/header/olympic-games.png";
import Indigenous from "../../../assets/scores-png/Indigenous.png";
import { sportList, IndigenousportsList } from "../data";
import "./style.scss";
import { useState, useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Scores = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("All Sports");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [sportsData, setSportsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const API_BASE = "https://admin.38nguk.in/api/get_all_sports_data";
    fetch("https://admin.38nguk.in/api/players")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch sports data");
        }
        return response.json();
      })
      .then((data) => {
        setSportsData(data?.data || []);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  // console.log(sportsData, "sportsdata");

  return (
    <div className="score-main ">
      <div className="sports">
        <p
          className={
            selectedSportType === "All Sports" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("All Sports")}
        >
          All Sports
        </p>
        {/* <p
          className={selectedSportType === "records" ? "sports-active-btn" : ""}
          onClick={() => {
            window.location.href = "/games/records";
          }}
        >
          Records
        </p>
        <p
          className={
            selectedSportType === "medal tally" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("medal tally")}
        >
          Medal Tally
        </p>

        <p
          className={selectedSportType === "venues" ? "sports-active-btn" : ""}
          onClick={() => setSelectedSportType("venues")}
        >
          Venues
        </p>
      
        <p
          className={
            selectedSportType === "fixtures" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("fixtures")}
        >
          Fixtures
        </p> */}
      </div>

      {selectedSportType === "Olympics Sports" ||
      selectedSportType === "All Sports" ? (
        <div className="sport-description">
          <img src={olympicLogo} alt="" className="olympic-img" />
          <p style={{ fontWeight: "600" }}>All Sports</p>
          <p className="des">
            The 38th National Games of India will feature a comprehensive range
            of Olympic sports, providing a platform for athletes to demonstrate
            their skills and compete at a high level. The event will emulate the
            structure and spirit of the Olympic Games
          </p>
          <div className="learn-more">
            <p>Learn More</p>
            <FaArrowRightLong />
          </div>
        </div>
      ) : selectedSportType === "Indigenous Sports" ? (
        <div className="sport-description">
          <img src={Indigenous} alt="" className="olympic-img" />
          <p style={{ fontWeight: "600" }}>Indigenous Sports</p>
          <p className="des">
            Indigenous sports hold a significant place in India's rich cultural
            heritage, representing traditional games that have been passed down
            through generations. For the 38th National Games of India, the
            inclusion of indigenous sports not only celebrates the diversity of
            the nation but also promotes physical fitness, community spirit, and
            cultural pride.
          </p>
          {/* <div className="learn-more">
            <p>Learn More</p>
            <FaArrowRightLong />
          </div> */}
        </div>
      ) : null}

      {/* <div
        className={`sport-list ${
          selectedSportType === "Indigenous Sports" ? "indigenous-sports" : ""
        }`}
      >
        {selectedSportType === "Olympics Sports" ? (
          <>
            {sportList
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((el, index) => {
                return (
                  <Link
                    to={el?.link?.isRelativePath ? el?.link?.path : ""}
                    {...(!el?.link?.isRelativePath
                      ? { target: "_blank", rel: "noopener noreferrer" }
                      : {})}
                    onClick={() => {
                      if (el?.link?.isRelativePath) {
                        setIsMenuOpen(false);
                      }
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    key={index}
                  >
                    <div className="each-game">
                      <div>
                        <div className="image-container-dropdowns">
                          <img src={el?.image} alt={el?.name} />
                        </div>
                      </div>

                      <div className="des">
                        <p className="name">{el?.name}</p>
                        <p className="description">{el?.description}</p>
                      </div>
                    </div>
                  </Link>
              
                );
              })}
          </>
        ) : selectedSportType === "Indigenous Sports" ? (
          <>
            {IndigenousportsList?.sort((a, b) =>
              a.name.localeCompare(b.name)
            )?.map((el, index) => (
              <Link
                to={el?.link?.isRelativePath ? el?.link?.path : ""}
                {...(!el?.link?.isRelativePath
                  ? { target: "_blank", rel: "noopener noreferrer" }
                  : {})}
                onClick={() => {
                  if (el?.link?.isRelativePath) {
                    setIsMenuOpen(false);
                  }
                }}
                style={{ textDecoration: "none", color: "white" }}
                key={index}
              >
                <div className="each-game">
                  <div>
                    <div className="image-container-dropdowns">
                      <img src={el?.image} alt={el?.name} />
                    </div>
                  </div>
                  <div className="des">
                    <p className="name">{el?.name}</p>
                    <p className="description">{el?.description}</p>
                  </div>
                </div>
              </Link>
            
            ))}
          </>
        ) : (
          [
            ...new Map(
              [...IndigenousportsList, ...sportList].map((sport) => [
                sport.name,
                sport,
              ])
            ).values(),
          ]
            .sort((a, b) => a.name.localeCompare(b.name))
            ?.map((el, index) => {
              return (
                <Link
                  to={el?.link?.isRelativePath ? el?.link?.path : ""}
                  {...(!el?.link?.isRelativePath
                    ? { target: "_blank", rel: "noopener noreferrer" }
                    : {})}
                  onClick={() => {
                    if (el?.link?.isRelativePath) {
                      setIsMenuOpen(false);
                    }
                  }}
                  style={{ textDecoration: "none", color: "white" }}
                  key={index}
                >
                  <div className="each-game">
                    <div>
                      <div
                        className="image-container-dropdowns-allsport"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <img
                          src={hoveredIndex === index ? el?.hoverimg : el?.image}
                          src={el?.image}
                          alt={el?.name}
                        />
                      </div>
                    </div>
                    <div className="des">
                      <p className="name">{el?.name}</p>
                      <p className="description">{el?.description}</p>
                    </div>
                  </div>
                </Link>

              
              );
            })
        )}
      </div> */}
{selectedSportType === "All Sports" && (
  <div className="sports-list-score">
    {sportsData.length > 0 ? (
      sportsData.map((el, index) => (
        <div className="sport-image-container" key={index}>
          <a href={`/sports/${el.rf_sport_db_name}`} style={{ textDecoration: 'none' }}>
            <img
              src={el?.sport_image_url}
              alt={el?.sport_name}
              className="sport-image"
            />
          </a>
        </div>
      ))
    ) : (
      <p className="no-image-message">No images available</p>
    )}
  </div>
)}


      {/* <div className="ktspl-Logo-subnav">
        <img src={ktspl} alt={ktspl} />
      </div> */}
    </div>
  );
};
export default Scores;
