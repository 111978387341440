import { FaArrowRightLong } from "react-icons/fa6";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { schedule_data } from "../data";
import ktspl from "../../../assets/home2/header/Khelotech-white-logo.Final one png.png";


import "./styles.scss"
const Schedule = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("Mauli(Mascot)");

  return (
    <div className="score-main">
      <div className="sports">
        {schedule_data.map((item, index) => {
          return (
            <p
              key={index} 
              className={`menuHeading ${selectedSportType === item.heading ? "sports-active-btn" : ""}`}
              onClick={() => setSelectedSportType(item.heading)}
            >
              {item.heading}
            </p>
          );
        })}
      </div>
      {schedule_data?.map((item) => {
        if (item.heading === selectedSportType) {
          return (
            <>
              <div className="sport-description">
                <img src={item?.img} alt="" className={item?.img} />
                <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                <p className="des">{item?.description}</p>
                <div className="learn-more">
                  <a
                    href={item?.link}
                    onClick={(e) => {
                      setIsMenuOpen(false);
                    }}
                    style={{ textDecoration: "none", color: "#fff" }}
                    target={item?.link.startsWith("http") ? "_blank" : "_self"}
                    
                  >
                    {" "}
                    <p>Learn More</p>
                  </a>
                  <FaArrowRightLong />
                </div>
              </div>
              {/* subsection to thew right */}
              <div className="sport-list">
                {item?.sideOptions?.map((el, index) => (
                  <div className="each-game" key={index}>
                    <img src={el?.image} alt={el?.name} />
                    <div className="des">
                      <p className="name">{el?.name}</p>
                      <p className="description">{el?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        }
        return null;
      })}

<div className="ktspl-Logo-subnav">
        <img src={ktspl} alt={ktspl} />{" "}
        {/* <p >KHELO TECH</p> */}
      </div>
    </div>
  );
};
export default Schedule;
