import axios from "axios";
import { parseAxiosError } from "components/common/constant";
import { OLD_NATIONAL_GAMES_API, NEW_NATIONAL_GAMES_API } from "./commonapi";

const API_BASE = "https://admin.38nguk.in/api/players";
// const API_BASE = "https://admin.37nationalgamesgoa.in/api/players";
const token = "2|3KmAUXmhVb24hNOBMGGvqJRPp07NYTLdJKcSb0wp";

export const fetchPlayersList = async (state) => {
  try {
    const response = await axios({
      method: "get",
      url: `${API_BASE}?token=${token}`,
      params: { token: token },
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};


export const fetchPlayersMedalsStateWise = async (state) => {
  try {
    const response = await axios({
      method: "get",
      url: `${API_BASE}/medals?token=${token}&state=${state}`,
      params: { token: token },
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchPlayersMedalsSportsWise = async (sport) => {
  try {
    const response = await axios({
      method: "get",
      url: `${API_BASE}/medals?token=${token}&sport=${sport}`,
      params: { token: token },
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};
