import { createSlice } from "@reduxjs/toolkit";
import {
  fetchElimination,
  fetchEventDetails,
  fetchLeaderboardData,
  fetchLeague,
  fetchOrderOfPlay,
  fetchParticularSportsEvents,
  fetchScoreDetails,
} from "api/sportsApiService";

import {fetchAllSports, fetchAllStateName} from "api/getAllSportsService";

const initialState = {
  sportsMainPage: null,
  orderOfPlay: null,
  currentSport: null,
  eventId: null,
  scoreDetails: null,
  orderOfPlayLoader: false,
  selectedOrderOfPlay: null,
  eliminationDetails: null,
  leaderboardData: null,
  eventDetails: null,
  leaugeData: null,
  allSportsData: null,
  allStateName: null,
};
const priorityOrder = {
  Finished: 2,
  Ongoing: 0,
  Upcoming: 1,
  "": 3,
};
const sportsSlice = createSlice({
  name: "sportsReducer",
  initialState,
  reducers: {
    setSportsMainPage: (state, action) => {
      state.sportsMainPage = action.payload;
    },
    setOrderOfPlay: (state, action) => {
      state.orderOfPlay = action.payload.sort(
        (a, b) =>
          priorityOrder[a?.status || ""] - priorityOrder[b?.status || ""]
      );
    },
    setCurrentSport: (state, action) => {
      state.currentSport = action.payload;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setScoreDetails: (state, action) => {
      state.scoreDetails = action.payload;
    },
    setOrderOfPlayLoader: (state, action) => {
      state.orderOfPlayLoader = action.payload;
    },
    setSelectedOrderOfPlay: (state, action) => {
      state.selectedOrderOfPlay = action.payload;
    },
    setEliminationDetails: (state, action) => {
      state.eliminationDetails = {
        ...action.payload,
        rounds: action?.payload?.rounds?.reduce((acc, item) => {
          if (item?.round_name === "Final") {
            return [
              ...acc,
              item,
              {
                ...item,
                round_name: "Winner",
                matches: [
                  {
                    ...item.matches[0],
                    team:
                      parseInt(item?.matches[0]?.team2_score) ===
                        parseInt(item?.matches[0]?.team1_score) &&
                      item?.matches?.[0]?.won !== "won-won"
                        ? item?.matches?.[0]?.won ===
                          item?.matches?.[0]?.team1?.name
                          ? item?.matches[0]?.team1
                          : item?.matches[0]?.team2
                        : parseInt(item?.matches[0]?.team2_score) >=
                          parseInt(item?.matches[0]?.team1_score)
                        ? item?.matches[0]?.team2
                        : item?.matches[0]?.team1,
                    teamWon:
                      parseInt(item?.matches[0]?.team2_score) ===
                        parseInt(item?.matches[0]?.team1_score) &&
                      item?.matches?.[0]?.won === "won-won"
                        ? item?.matches[0]?.team1
                        : null,
                    team_score:
                      parseInt(item?.matches[0]?.team2_score) >=
                      parseInt(item?.matches[0]?.team1_score)
                        ? item?.matches[0]?.team2_score
                        : item?.matches[0]?.team1_score,
                  },
                ],
              },
            ];
          }
          return [...acc, item];
        }, []),
        bronze_medal_match: action?.payload?.bronze_medal_match
          ? {
              ...action?.payload?.bronze_medal_match,
              matches: [
                ...action?.payload?.bronze_medal_match?.matches,
                {
                  ...action?.payload?.bronze_medal_match?.matches[0],
                  team:
                    parseInt(
                      action?.payload?.bronze_medal_match?.matches[0]
                        ?.team2_score
                    ) >=
                    parseInt(
                      action?.payload?.bronze_medal_match?.matches[0]
                        ?.team1_score
                    )
                      ? action?.payload?.bronze_medal_match?.matches[0]?.team2
                      : action?.payload?.bronze_medal_match?.matches[0]?.team1,
                  team_score:
                    parseInt(
                      action?.payload?.bronze_medal_match?.matches[0]
                        ?.team2_score
                    ) >=
                    parseInt(
                      action?.payload?.bronze_medal_match?.matches[0]
                        ?.team1_score
                    )
                      ? action?.payload?.bronze_medal_match?.matches[0]
                          ?.team2_score
                      : action?.payload?.bronze_medal_match?.matches[0]
                          ?.team1_score,
                },
              ],
            }
          : null,
      };
    },
    setLeaderboardData: (state, action) => {
      state.leaderboardData = action.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
    setLeaugeData: (state, action) => {
      state.leaugeData = action.payload;
    },
    setAllSportsData: (state, action) => {
      state.allSportsData = action.payload;
    },
    setAllStateName: (state, action) => {
      state.allStateName = action.payload;
    },
  },
});

export const {
  setSportsMainPage,
  setOrderOfPlay,
  setEventId,
  setCurrentSport,
  setScoreDetails,
  setOrderOfPlayLoader,
  setSelectedOrderOfPlay,
  setEliminationDetails,
  setLeaderboardData,
  setEventDetails,
  setLeaugeData,
  setAllSportsData,
  setAllStateName
} = sportsSlice.actions;

export const getSportsMainData = (param = "") => {
  return async (dispatch) => {
    try {
      const res = await fetchParticularSportsEvents(param);
      dispatch(setSportsMainPage(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllSportsData = (sportname) => {
  return async (dispatch) => {
    try {
      const res = await fetchAllSports(sportname);
      dispatch(setAllSportsData(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const getAllStateName = () => {
  return async (dispatch) => {
    try {
      const res = await fetchAllStateName();
      dispatch(setAllStateName(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getOrderOfPlay = (props) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setOrderOfPlayLoader(true));
      const { sportsMainPage, eventId } = getState().sportsReducer;
      const sportId = sportsMainPage?.sport_id;
      const res = await fetchOrderOfPlay({
        sportId: props?.sId ?? sportId,
        eventId: props?.eId ?? eventId,
      });
      // console.log(res, "==>")
      const data = res?.data?.data?.sort(
        (a, b) => new Date(b?.schedule) - new Date(a?.schedule)
      );
      dispatch(setOrderOfPlay(data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setOrderOfPlayLoader(false));
    }
  };
};

export const getScoreDetails = (param) => {
  return async (dispatch, getState) => {
    try {
      const { sportsMainPage } = getState().sportsReducer;
      const sport_id = sportsMainPage?.sport_id;
      const { eventId, matchId, sportId } = param;
      const res = await fetchScoreDetails({
        sportId: sportId ?? sport_id,
        eventId,
        matchId,
      });
      dispatch(setScoreDetails(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getEliminationDetails = (param) => {
  return async (dispatch, getState) => {
    try {
      const { sportId, eventId } = param ?? {};
      const res = await fetchElimination({ sportId, eventId });
      dispatch(setEliminationDetails(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getLeaderboardData = (param) => {
  return async (dispatch, getState) => {
    try {
      const { sportId, eventId, matchId } = param ?? {};
      const res = await fetchLeaderboardData({ sportId, eventId, matchId });
      dispatch(setLeaderboardData(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getEventDetails = (param) => {
  return async (dispatch, getState) => {
    try {
      const { sport } = param ?? {};
      const res = await fetchEventDetails({ sport });
      dispatch(setEventDetails(res?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getLeagueData = (param) => {
  return async (dispatch, getState) => {
    try {
      const { sportId, eventId } = param ?? {};
      const res = await fetchLeague({ sportId, eventId });
      dispatch(setLeaugeData(res?.data?.league));
    } catch (error) {
      console.error(error);
    }
  };
};

export default sportsSlice.reducer;
