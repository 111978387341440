import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import ukLogo from "../../../assets/home2/Body/logo-color.png";
// import ukHindiLogo from "../../../assets/home2/header/logo-hindi.png";
// import ukLogoColorHindi from "../../../assets/home2/header/ukLOgoColorHindi.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NavLogo = ({ click, isScrolled }) => {
  const history = useHistory();
  const [showFirst, setShowFirst] = useState(true);
  const [isFading, setIsFading] = useState(false); // For controlling the fade effect

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Start fading
      setTimeout(() => {
        setShowFirst((prev) => !prev); // Toggle image
        setIsFading(false); // End fading
      }, 500); // Match the duration of the fade-out effect
    }, 2000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div
      style={{ zIndex: 1100 }}
      className="navbar-brands cursor-pointer"
      onClick={() => {
        click();
        history.push("/");
      }}
    >
      <div style={{ padding: "0.5rem" }} className="navbar-imgs">
        {isScrolled ? (
          <Link to="/">
            <img
              loading="lazy"
              src={ukLogo}
              alt={
                showFirst
                  ? "National Games White Logo"
                  : "National Games Hindi Logo"
              }
              // className={`fade-transition ${isFading ? "fading" : ""}`} // Add dynamic class
              // style={{
              //   width: showFirst ? "150px" : "130px", 
              //   // height: showFirst ? "80px" : "80px"
              // }}
            />
          </Link>
        ) : (
          <Link to="/">
            <img
              loading="lazy"
              // src={showFirst ? ukLogo : ukHindiLogo}
              src={ukLogo}
              alt={
                showFirst
                  ? "National Games White Logo"
                  : "National Games Hindi Logo"
              }
              // className={`fade-transition ${isFading ? "fading" : ""}`} 
              // style={{
              //   width: showFirst ? "150px" : "130px", 
              //   // height: showFirst ? "150px" : "130px"
              // }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default NavLogo;
