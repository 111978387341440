import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./sports.css";
// import {useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSportsData,
  getSportsMainData,
} from "redux/reducers/sportsReducer";
import dayjs from "dayjs";
import axios from "axios";

const SportEventbannerCard = () => {
  const { sportsMainPage: sportsData } = useSelector(
    (state) => state.sportsReducer
  );
  // const [sportsData, setSportsData] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { allSportsData: allsportsData } = useSelector(
    (state) => state.sportsReducer
  );

  // Extract sport name from window location pathname
  const sportName = window.location.pathname.split("/")[2]; 
 
  const transformedParam =
    sportName?.toLocaleLowerCase() === "kho-kho"
      ? sportName
      : sportName?.toLocaleLowerCase() === "canoeing"
      ? "kayaking_and_canoeing"
      : sportName?.toLocaleLowerCase()?.split("-").join("_");
      
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await getAllSportsData(transformedParam)

  //       setSportsData(response);
  //       setLoading(false);
  //     } catch (err) {
  //       setError("Failed to load data");
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [sportName]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSportsMainData(transformedParam)).finally(() =>
      setLoading(false)
    );
  }, [sportName, dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllSportsData(transformedParam)).finally(() =>
      setLoading(false)
    );
  }, [sportName, dispatch]);


  const sportsMainPage = sportsData || [];
  const allSportsData =  allsportsData || []

  const filteredSportsData = allSportsData.filter(
    (sport) => sport?.rf_sport_db_name === transformedParam
  );
  //  console.log(allSportsData, "m")

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  useEffect(() => {
    if (sportsMainPage) {
      // Extract only the relevant fields
      const localStorageData = {
        no_of_events: sportsMainPage.no_of_events,
        no_of_participants: sportsMainPage.no_of_participants,
        event_start_date: sportsMainPage.event_start_date,
        event_end_date: sportsMainPage.event_end_date,
        venue: sportsMainPage.venue,
      };

      // Save to localStorage
      localStorage.setItem(
        "sportsMainPage_data",
        JSON.stringify(localStorageData)
      );
    }
  }, [sportsMainPage]);

  const localSportsData =
    JSON.parse(localStorage.getItem("sportsMainPage_data")) || {};

    console.log(filteredSportsData, "==>")

  return (
    <div className="schedule-info-container ">
      <Row className="text-lg-center py-lg-3 justify-content-start align-items-center schedule-row px-md-2">
        {/* Events */}
        <Col xs={6} md={3} className="info-box">
          <p>Events</p>
          <h3>{localSportsData.no_of_events || "TBD"}</h3>
        </Col>

        {/* Participants */}
        <Col xs={6} md={3} className="info-box">
          <p>Teams/Participants</p>
          <h3>{localSportsData.no_of_participants || "TBD"}</h3>
        </Col>

        {/* Date */}
        <Col xs={6} md={3} className="info-box">
          <h3>Date</h3>
          <h5>
            {localSportsData.event_start_date
              ? `${dayjs(localSportsData.event_start_date).format(
                  "DD MMM"
                )} - ${dayjs(localSportsData.event_end_date).format(
                  "DD MMM"
                )}`
              : "TBD"}
          </h5>
        </Col>

        {/* Location */}
        <Col xs={6} md={3} className="info-box">
          <h3>Location</h3>
          {console.log(allSportsData?.venue_name,"==>")}
          <h5>{localSportsData.venue?.trim() || filteredSportsData[0]?.venue_name|| "TBD"}</h5>
        </Col>
      </Row>
    </div>
  );
};

export default SportEventbannerCard;
