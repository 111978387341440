import axios from "axios";
import { parseAxiosError } from "components/common/constant";
import { OLD_NATIONAL_GAMES_API, NEW_NATIONAL_GAMES_API } from "./commonapi";

const API_BASE = "https://admin.38nguk.in/api/mock";
// const API_BASE = "https://admin.37nationalgamesgoa.in/api/mock";

export const fetchParticularSportsEvents = async (param) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/event_details?sport=${param}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};


export const fetchOrderOfPlay = async ({ sportId, eventId }) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/order_of_play?sport_id=${sportId}&event_id=${eventId}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchScoreDetails = async ({ sportId, eventId, matchId }) => {
  try {
    const response = await axios({
      method: "get",
      url:
        API_BASE +
        `/scorecard?sport_id=${sportId}&event_id=${eventId}&match_id=${matchId}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchElimination = async ({ sportId = null, eventId = null }) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/draw_system?sport_id=${sportId}&event_id=${eventId}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};

export const fetchLeaderboardData = async ({
  sportId,
  eventId,
  matchId = "latest",
}) => {
  try {
    const response = await axios({
      method: "get",
      url:
        API_BASE +
        `/leaderboard?sport_id=${sportId}&event_id=${eventId}&match_id=${matchId}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};


export const fetchEventDetails = async (sport) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/event_details?sport=${sport}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};


export const fetchLeague = async ({ sportId, eventId }) => {
  try {
    const response = await axios({
      method: "get",
      url:
        API_BASE +
        `/round_robin_league?sport_id=${sportId}&event_id=${eventId}`,
    });

    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};
