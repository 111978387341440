import styles from "./SchedulePage.module.css";
import React, {useState } from "react";
import ScrollTopTop from "components/common/scrollToTop";
import Banner from "components/common/banner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "react-bootstrap";
// import racer from "../../assets/home2/Cluster/logo-color.png";
// import india from "../../assets/home2/Cluster/india.png"
import "./style.css";

export const getPropertyNoCase = (obj, prop) => {
  return obj[
    Object.keys(obj)?.find((key) =>
      prop?.toLowerCase()?.includes(key.toLowerCase())
    )
  ];
};

function ScheduleAndResults({ children }) {

  const history = useHistory();
  const currentPath = window.location.pathname; // "/schedule/overview"
  const lastSegment = currentPath.split("/").filter(Boolean).pop(); // "overview"

  const scheduleMenu = [
    { title: "Overview", path: "overview" },
    { title: "Daily Schedule", path: "daily" },
    { title: "Cluster", path: "cluster" }
  ];
  const [activeState, setActiveState] = useState(
    scheduleMenu?.find((menu) => menu?.path === lastSegment)?.title || "Overview"
  );

  const menuHandler = (ele) => {
    setActiveState(ele);
    history.push(`/schedule/${ele?.toLowerCase()}`);
  };

  return (
    <ScrollTopTop>
      <div className={styles.page}>
        <Banner text="Schedule & Results" hideSection="gallery" pagetitle="Schedule & Results" subPageTitle={lastSegment} />
        <Row className="d-flex justify-content-center">
          <Col lg={10} className="" style={{ position: "relative" }}>
            {/* <div className={styles.schedule_navbar}>{nav_element}</div> */}
            <Row className={styles.schedule_navbar}>
              {/* <Col xl={3} lg={2} md={2} sm={3}>
                <img src={racer} alt={racer} className="schedule_uttar_img" />
              </Col> */}
              <Col xl={8} lg={12} md={8} sm={6} className="d-flex justify-content-center schedule-margin-top">
                <ul className="d-flex justify-content-between w-100 w-xxl-80 shedule_active_menu_container align-items-center">
                {scheduleMenu?.map((data) => (
                    <li
                      key={data?.title}
                      className={`${ activeState === data?.title ? "schedule_active_menu" : "schedule__menu_title" }`} 
                      onClick={() => menuHandler(data?.path)} 
                    >
                      {data?.title}
                    </li>
                  ))}
                
              </ul>
            </Col>
            {/* <Col xl={3} lg={2} md={2} sm={3}>
              <img src={india} alt={india} className="schedule_india_img"/>
            </Col> */}
          </Row>
          </Col>
        </Row>

        {children}
      </div>
    </ScrollTopTop>
  );
}

export default ScheduleAndResults;
